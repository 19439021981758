<template>
  <div class="dealer">
    <v-dialog v-model="associatedCarBrandsDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Update Associated Car Brands
        </v-card-title>
        <v-virtual-scroll
          :bench="benched"
          :items="dealerCarBrandsList"
          height="300"
          item-height="64"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.carBrandId">
              <v-list-item-content>
                <img
                  style="height:50px; width: 50px; flex:none"
                  v-if="item.carBrandImgUrl != null"
                  :src="item.carBrandImgUrl"
                />
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ item.carBrandName }}</strong>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  fab
                  small
                  depressed
                  color="red"
                  @click="deleteFromCarBrandList(item)"
                >
                  <v-icon medium color="white">
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>

        <v-card-text>
          <v-autocomplete
            v-model="selectedCarBrand"
            :items="carBrandSearchEntries"
            :search-input.sync="search"
            :loading="isLoading"
            prepend-icon="mdi-database-search"
            return-object
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-text>
          <v-btn
            style="float:right"
            small
            depressed
            color="primary"
            @click="addDealerCarBrand(selectedCarBrand)"
          >
            <v-icon medium color="white">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              doUpdateDealerCarBrands(
                assignDealerTypeData.dealerId,
                dealerCarBrandsList
              )
            "
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Status
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="editDealer.status"
            :items="dealerStatusList"
            item-value="value"
            item-text="text"
            label="Dealer Status"
          >
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editNameDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Name
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="editDealer.name" label="Dealer Name">{{
            editDealer.name
          }}</v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editPhotoDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Profile Picture
        </v-card-title>
        <v-card-text>
          <v-file-input
            label="Logo"
            @change="chooseImage"
            v-model="imgFile"
            clearable
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCreditDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} credit balance
        </v-card-title>
        <br />
        <v-card-text>
          <v-text-field
            type="number"
            prefix="$"
            v-model="editDealer.creditBalance"
            label="Credit balance"
            >{{ editDealer.creditBalance }}</v-text-field
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDescriptionDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editDealer.name }} Description
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="editDealer.description"
            :rows="2"
            auto-grow
            label="Description"
            >{{ editDealer.description }}</v-textarea
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditDealer()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="assignPlatformDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Assign {{ assignPlatformData.name }} Platforms
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-for="platform in platformList"
            :key="platform.id"
            v-model="assignPlatformData.platformIds"
            :label="platform.name"
            :value="platform.id"
          ></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="doAssignPlatforms"
            :disabled="assignPlatformData.platformIds.length === 0"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="assignDealerTypeDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Assign {{ assignDealerTypeData.name }} Type
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-for="type in dealerTypeList"
            :key="type.id"
            v-model="assignDealerTypeData.dealerTypeId"
            :label="type.dealerType"
            :value="type.id"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="showDealerCarBrands(assignDealerTypeData.dealerId)"
          >
            Associated Car Brands
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="doAssignDealerTypes"
            :disabled="assignDealerTypeData.dealerTypeId.length === 0"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="assignAccountManagerDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Assign Account Manager
        </v-card-title>
        <v-card-text>
          <v-select
            :items="accountManagerList"
            v-model="assignAccountManagerData.accountManagerId"
            item-value="id"
            item-text="name"
            label="Select Account Manager"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="doAssignAccountManager"
            :disabled="assignAccountManagerData.accountManagerId === ''"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchDealer()"
          v-model="dealerSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="dealerStatus"
          :items="dealerStatusList"
          item-value="value"
          item-text="text"
          label="Car Status"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchDealer()"
          @keyup.enter.native="searchDealer()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="dealerList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-btn class="mx-2" dark small @click="editNameButtonClick(item)">
          {{ item.name }}
        </v-btn>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <v-btn
          class="mx-2"
          dark
          color="primary"
          small
          @click="editDescriptionButtonClick(item)"
        >
          {{
            item.description == null
              ? "Null"
              : item.description.substring(0, 5) + "..."
          }}
        </v-btn>
      </template>
      <template v-slot:[`item.logoPath`]="{ item }">
        <v-btn class="mx-2" dark small @click="editPhotoButtonClick(item)">
          <img
            style="height:50px;"
            v-if="item.logoPath != null"
            :src="item.logoPath"
          />
        </v-btn>
      </template>

      <template v-slot:[`item.creditBalance`]="{ item }">
        <v-btn
          class="mx-2"
          dark
          small
          :color="item.creditBalance === 0 ? 'warning' : 'primary'"
          @click="editCreditButtonClick(item)"
        >
          {{ item.creditBalance }}
        </v-btn>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-btn
          class="mx-2"
          dark
          small
          :color="
            item.status === 'REJECTED'
              ? 'error'
              : item.status === 'PENDING_APPROVAL'
              ? 'warning'
              : 'primary'
          "
          @click="editButtonClick(item)"
        >
          {{ item.status }}
        </v-btn>
      </template>
      <template v-slot:[`item.accountManager`]="{ item }">
        <v-btn class="mx-2" dark small @click="assignAccountManagerClick(item)">
          {{ item.accountManagerName ? item.accountManagerName : "null" }}
        </v-btn>
      </template>
      <template v-slot:[`item.platforms`]="{ item }">
        <v-btn class="mx-2" dark small @click="assignPlatformClick(item)">
          {{ item.platforms.map(p => p.name).join(",") }}
        </v-btn>
      </template>
      <template v-slot:[`item.dealerAssociatedTypes`]="{ item }">
        <v-btn
          class="mx-2"
          dark
          small
          @click="assignDealerAssociatedTypesClick(item)"
        >
          {{ item.dealerAssociatedTypes.map(p => p.dealerType).join(",") }}
        </v-btn>
      </template>
      <template v-slot:[`item.hideCar`]="{ item }">
        <v-switch
          v-model="item.hideCarFlag"
          @change="onToggleHide(item)"
        ></v-switch>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import uploadImage from "../../../services/image-uploader";

import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      selectedCarBrand: null,
      isLoading: false,
      dealerCarBrandsList: [],
      benched: 0,
      entries: [],
      search: null,
      dealerSearch: "",
      dealerStatus: "",
      imgFile: null,
      imgUrl: null,
      pageCount: 0,
      associatedCarBrandsDialog: false,
      editDialog: false,
      editNameDialog: false,
      editPhotoDialog: false,
      editCreditDialog: false,
      editDescriptionDialog: false,
      assignAccountManagerDialog: false,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Logo", value: "logoPath" },
        { text: "Master SP Email", value: "masterSalespersonEmail" },
        { text: "Phone", value: "mobilePhone" },
        { text: "Address", value: "address" },
        { text: "UEN", value: "uenNum" },
        { text: "AD", value: "authorizedFlag" },
        { text: "Credit Balance", value: "creditBalance" },
        { text: "Status", value: "status" },
        { text: "Manager", value: "accountManager" },
        { text: "Platforms", value: "platforms", sortable: false },
        { text: "Type", value: "dealerAssociatedTypes", sortable: false },
        { text: "Hide Car", value: "hideCar" },
      ],
      dealerList: [],
      editDealer: {
        name: "",
        dealerId: "",
        status: "",
        creditBalance: 0,
        logoName: "",
        logoPath: "",
        description: "",
      },
      dealerStatusList: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "REJECTED", value: "REJECTED" },
      ],
      platformList: [],
      dealerTypeList: [],
      accountManagerList: [],
      assignPlatformDialog: false,
      assignDealerTypeDialog: false,
      assignPlatformData: {
        platformIds: [],
        name: "",
        dealerId: "",
      },
      assignDealerTypeData: {
        dealerTypeId: [],
        name: "",
        dealerId: "",
      },
      assignAccountManagerData: {
        accountManagerId: "",
        dealerId: "",
      },
    };
  },
  computed: {
    carBrandSearchEntries() {
      const currectDealerCarBrandIds = this.dealerCarBrandsList.map(
        x => x.carBrandId
      );
      var d = this.entries
        .filter(x => currectDealerCarBrandIds.indexOf(x.id) < 0)
        .map(x => ({
          text: x.name,
          value: x.id,
          disabled: false,
          logoPath: x.logoPath,
          name: x.name,
          id: x.id,
        }));
      return d;
    },
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      console.log("Loading here: " + val);

      // Lazily load input items
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carBrand?searchContent=${val}`
      )
        .then(res => {
          this.entries = res.data.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.fetchDealerList();
    this.fetchPlatforms();
    this.fetchDealerTypes();
    this.fetchAccountManagers();
  },
  methods: {
    chooseImage() {
      this.editDealer.logoName = this.imgFile.name;
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    searchDealer() {
      this.fetchDealerList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchDealerList();
    },
    editButtonClick(item) {
      this.editDealer.name = item.name;
      this.editDealer.status = item.status;
      this.editDealer.dealerId = item.id;
      this.editDealer.creditBalance = item.creditBalance;
      this.editDealer.logoPath = item.logoPath;
      this.editDealer.description = item.description;

      this.editDialog = true;
    },
    editCreditButtonClick(item) {
      this.editDealer.name = item.name;
      this.editDealer.status = item.status;
      this.editDealer.dealerId = item.id;
      this.editDealer.creditBalance = item.creditBalance;
      this.editDealer.logoPath = item.logoPath;
      this.editDealer.description = item.description;

      this.editCreditDialog = true;
    },
    editPhotoButtonClick(item) {
      this.editDealer.name = item.name;
      this.editDealer.status = item.status;
      this.editDealer.dealerId = item.id;
      this.editDealer.creditBalance = item.creditBalance;
      this.editDealer.logoPath = item.logoPath;
      this.editDealer.description = item.description;
      this.editPhotoDialog = true;
    },
    editNameButtonClick(item) {
      this.editDealer.name = item.name;
      this.editDealer.status = item.status;
      this.editDealer.dealerId = item.id;
      this.editDealer.creditBalance = item.creditBalance;
      this.editDealer.logoPath = item.logoPath;
      this.editDealer.description = item.description;

      this.editNameDialog = true;
    },
    editDescriptionButtonClick(item) {
      this.editDealer.name = item.name;
      this.editDealer.status = item.status;
      this.editDealer.dealerId = item.id;
      this.editDealer.creditBalance = item.creditBalance;
      this.editDealer.logoPath = item.logoPath;
      this.editDealer.description = item.description;

      this.editDescriptionDialog = true;
    },
    assignPlatformClick(item) {
      this.assignPlatformData.platformIds = item.platforms.map(p => p.id);
      this.assignPlatformData.name = item.name;
      this.assignPlatformData.dealerId = item.id;
      this.assignPlatformDialog = true;
    },
    assignDealerAssociatedTypesClick(item) {
      this.assignDealerTypeData.dealerTypeId = item.dealerAssociatedTypes.map(
        p => p.id
      );
      this.assignDealerTypeData.name = item.name;
      this.assignDealerTypeData.dealerId = item.id;
      this.assignDealerTypeDialog = true;
    },
    assignAccountManagerClick(item) {
      this.assignAccountManagerData.accountManagerId = item.accountManager;
      this.assignAccountManagerData.dealerId = item.id;
      this.assignAccountManagerDialog = true;
    },
    async doEditDealer() {
      const self = this;
      try {
        if (self.imgFile != null) {
          const imgRes = await uploadImage(self.imgFile);
          if (imgRes.data.httpStatus !== "OK") {
            console.log(imgRes.data.desc);
            return;
          }
          self.editDealer.logoPath = imgRes.data.data;
        }
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          self.editDealer
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.editDialog = false;
            self.editNameDialog = false;
            self.editDescriptionDialog = false;
            self.editCreditDialog = false;
            self.editPhotoDialog = false;
            self.fetchDealerList();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    fetchDealerList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.dealerSearch,
        status: self.dealerStatus,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer`,
        params
      ).then(res => {
        if (res.data) {
          self.dealerList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    fetchPlatforms() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/platform`)
        .then(res => {
          self.platformList = res.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    fetchDealerTypes() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/baseDataSystem/dealerType`)
        .then(res => {
          self.dealerTypeList = res.data.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    fetchAccountManagers() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/userSystem/accountManager`)
        .then(res => {
          self.accountManagerList = res.data.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    doAssignAccountManager() {
      const self = this;
      const { dealerId, accountManagerId } = this.assignAccountManagerData;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/assignAccountManager`,
        {
          dealerId,
          accountManagerId,
        }
      )
        .then(() => {
          self.fetchDealerList();
        })
        .catch(err => {
          console.error(err);
          self.$emit("error", err);
        })
        .finally(() => {
          self.assignAccountManagerDialog = false;
        });
    },
    doAssignPlatforms() {
      const self = this;
      const { dealerId, platformIds } = this.assignPlatformData;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/assignDealerPlatform`,
        {
          dealerId,
          platformIds,
        }
      )
        .then(() => {
          self.fetchDealerList();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.assignPlatformDialog = false;
        });
    },

    doAssignDealerTypes() {
      const self = this;
      const { dealerId, dealerTypeId } = this.assignDealerTypeData;
      const dealerTypes = dealerTypeId;
      putAxios(`${process.env.VUE_APP_SERVER_URL}/dealer/updDealerTypes`, {
        dealerId,
        dealerTypes,
      })
        .then(() => {
          self.fetchDealerList();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.assignDealerTypeDialog = false;
        });
    },
    showDealerCarBrands(dealerId) {
      this.fetchDealerCarBrands(dealerId);
      this.associatedCarBrandsDialog = true;
      console.log(this.dealerCarBrandsIdContext);
    },
    fetchDealerCarBrands(dealerId) {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealerInfo/associatedCarBrand?dealerId=${dealerId}`
      )
        .then(res => {
          self.dealerCarBrandsList = res.data.data;
          // self.dealerCarBrandsList.forEach(d => {
          //   self.dealerCarBrandsIdContext.push(d.carBrandId);
          // });
        })
        .catch(err => {
          console.error(err);
        });
    },
    addDealerCarBrand(selectedCarBrand) {
      this.dealerCarBrandsList.push({
        carBrandId: selectedCarBrand.id,
        carBrandName: selectedCarBrand.name,
        carBrandImgUrl: selectedCarBrand.logoPath,
      });
    },
    doUpdateDealerCarBrands(dealerId, dealerCarBrandsList) {
      const self = this;
      const carBrandId = dealerCarBrandsList.map(m => m.carBrandId);

      putAxios(`${process.env.VUE_APP_SERVER_URL}/dealer/updDealerCarBrands`, {
        dealerId,
        carBrandId,
      })
        .then(() => {
          self.fetchDealerCarBrands(self.assignDealerTypeData.dealerId);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.associatedCarBrandsDialog = false;
        });
    },
    deleteFromCarBrandList(selected) {
      this.dealerCarBrandsList.forEach((d, i) => {
        if (d.carBrandId == selected.carBrandId) {
          this.dealerCarBrandsList.splice(i, 1);
        }
      });
    },

    onToggleHide(item) {
      const self = this;
      try {
        const data = {
          dealerId: item.id,
          hideCarFlag: item.hideCarFlag ? 1 : 0,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchDealerList();
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
